import React, { useState, useEffect } from "react";
import Layout from "../../../components/chat-channel/Layout";
import { useMedia } from "use-media";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Container from "../../../components/chat-channel/Container";
import TitleAndMetaTags from "../../../components/common/TitleAndHeader";
import { RequestForm } from "../../../components/form";
import { OnScrollPopup } from "../hr-chatbot";
import { GoToPopup } from "../../homepage";
import { CookiesPoup } from "../../homepage";
import {
  cta_1,
  cta_1_mob,
  cta_2,
  cta_2_mob,
  ExistingBlogCta,
} from "./4-reasons-to-use-ms-teams-as-a-digital-helpdesk";
import { redirectToWithUtm } from "../../../components/Header";
import "react-accessible-accordion/dist/fancy-example.css";

const topImage = require("../../../assets/images/market_ui/goto_testimonial_cta.png");
const BlogHeader = require("../../../assets/img/blogs/header_blog_images_7.png.webp");

const ldSchema = {
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  mainEntityOfPage: {
    "@type": "WebPage",
    "@id":
      "https://workativ.com/conversational-ai-platform/slack-chatbot-guide",
  },
  headline: "Slack Chatbot Guide for your Business",
  image: "",
  author: {
    "@type": "Organization",
    name: "Team Workativ",
  },
  publisher: {
    "@type": "Organization",
    name: "Workativ",
    logo: {
      "@type": "ImageObject",
      url:
        "https://workativ.com/static/workativ-logo-5eea21f2dcd20bbbbf52debb41c0f26e.png",
    },
  },
  datePublished: "2021-08-05",
  dateModified: "2021-08-05",
};

export default function FeatureHomePage() {
  const isSmall = useMedia({ maxWidth: "520px" });

  return (
    <>
      <TitleAndMetaTags
        title="Intelligent Automation for the Digital Enterprise Salvation "
        description="For companies struggling to leverage digital transformation, there is new hero in town, and it’s called intelligent automation."
        keywords={[
          "Slack Chatbot, Automation, AI Chatbot",
          "workativ free trial",
          "Slack chatbot free trial",
          "Slack Chatbot",
        ]}
        ogImage={BlogHeader}
        schema={ldSchema}
      />
      <section className="market_main_wrapper">
        <Container>
          <Layout backgroundColor={"_bg_market"} logoFor="ASSISTANT">
            <div className="grid_container_chatbot">
              <div className="container">
                <div className="row">
                  <div className="grid_container_chatbot_wrapper">
                    <div className="grid__wrapper">
                      <div className="col-lg-12 col-md-12 col-xs-12 trial_header_left">
                        <div className="main-slider-left-market">
                          <h1 className="font-page-header ">
                            Intelligent Automation for the Digital Enterprise
                            Salvation
                          </h1>
                        </div>
                      </div>
                      <img src={BlogHeader} />
                      <div className=" mt-5 market_wrapper_page">
                        <h6 className="font-section-sub-header-small-bold">
                          Intelligent Automation to the digital rescue.
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          For companies struggling to leverage digital
                          transformation, there is new hero in town, and it’s
                          called{" "}
                          <a href="https://workativ.com/assistant/workflow-automation">
                            intelligent automation.
                          </a>{" "}
                          Intelligent automation is definitely much more than
                          hype. But before companies carried away, they must
                          understand and focus more on the “why”, “where” and
                          importantly “how” they want to use intelligent
                          automation. Understand that the why is really about
                          the business case, and how, being the approach (tools
                          and process) and with where being the use case. It is
                          imperative for business, be a SMB or enterprise to
                          understated not just how this new solution can
                          benefit, but also its limitations. For example, this
                          is NOT a complete replacement for human, but augment
                          and for sure to a large degree supports reduction in
                          repetitive roles. The benefits are many, and depending
                          on the use case, certainly can help increase bottom
                          line, bring innovation, deploy new channels for better
                          experience, but care must be made to fully understand
                          and set expectations.
                        </p>
                        <h2 className="font-section-sub-header-small-bold">
                          Intelligent Assistants on the rescue
                        </h2>
                        <p class="font-section-normal-text line-height-2">
                          One of the key aspects that differentiate a chatbot
                          and an intelligent or
                          <a href="https://workativ.com/conversational-ai-platform/conversational-ai-chatbot">
                            {" "}
                            cognitive assistance{" "}
                          </a>
                          is that the latter is able to demonstrate greater
                          impact on business outcomes via automation. Chatbots
                          fall into category of a conversational FAQ, with a
                          chat interface. Cognitive Assistants on the other hand
                          have the potential to demonstrate real value if
                          implemented correctly. Some of the key benefits that
                          early adopters of cognitive assistance are seeing are
                          not just on the front office, but also serving
                          internal operations. As an example,{" "}
                          <a href="https://workativ.com/conversational-ai-platform/blog/for-it-helpdesk-proper-process-for-remote-working-is-the-need-of-the-hour-in-the-time-of-covid-19">
                            cognitive assistant can help reduce IT Help Desk
                            costs
                          </a>{" "}
                          buy
                          <a href="https://workativ.com/conversational-ai-platform/blog/help-desk-automation-employee-experience">
                            automating and servicing employee requests
                          </a>{" "}
                          and issues at workplace spanning several functions
                          such as IT, HR etc.
                        </p>
                      </div>

                      <NocodeWrapper />

                      <div>
                        <h6 className="font-section-sub-header-small-bold  ">
                          Key guidance whilst considering Cognitive Assistance
                        </h6>
                        <h6 className="font-section-sub-header-small-bold mt-5">
                          Build Vs buy
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          As with all things IT, one of the biggest debates for
                          CIOs and companies is “Build” Vs Buy. And same goes
                          for cognitive assistance. We have seen that companies
                          typically engage with service integrators to build
                          cognitive assistance. Now with any new development
                          project, there are many risks involved. So, companies
                          need to evaluate if they are ready to take the risks
                          especially deploying cognitive assistance is not an
                          upgrade, but it’s a transformation. And mistake can be
                          costly not just from cost perspective but also leave a
                          big mark on the employee experience and potentially
                          impact productivity.
                        </p>
                        <h6 className="font-section-sub-header-small-bold">
                          Setting “right” expectations
                        </h6>

                        <p class="font-section-normal-text line-height-2">
                          Most users in general are familiar with Alexa or Siri,
                          so the good news is, the modern user understand the
                          art of possible so it is very critical to set the
                          right expectations in what and how the cognitive
                          assistant will deliver value ( i.e. improve support
                          experience and positively impact their productivity).
                          Identify areas that VA will need to impact. For
                          example, the types of tasks that can be automated and
                          the real impact to the experience. Many
                          implementations fall through the cracks also because
                          of low usage or poor adoption strategies. Ultimately,
                          do you want to build an Alexa for enterprise, or do
                          you want to solve workplace issues for your employees
                          in a better, quicker and easiest way possible. Note
                          that the VA will need a lot of historic data to make
                          it “truly” intelligent. so, understand that, for magic
                          to happen, we need that secret sauce (read data and
                          training).
                        </p>

                        <h6 className="font-section-sub-header-small-bold">
                          Adoption (read culture)
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          We know that adoption can make or break a project.
                          Companies implementing VA should, and must prepare
                          well for change and adoption. This must include a
                          comprehensive training and communication plan followed
                          by user progressive and constant user experience
                          feedback. There is also a great emphasis on culture.
                          We have seen that not all companies are open to
                          self-service, but this is also due to lack of
                          communication. The benefits of BVA are many so
                          companies must invest both time and attention to help
                          users “learn” this new way of workplace support. And
                          it’s in the best interest of not just the business but
                          also users, as no one wants to be on a support call
                          for half an hour!.
                        </p>
                      </div>
                      <ExistingBlogCta
                        ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
                        ButtonText="Book a Demo"
                        isColor="black"
                        backgroundImage={cta_1}
                        mobileBackgroundImage={cta_1_mob}
                      />
                      <div className="most_popular_links">
                        <div className="most_popular_header font-section-sub-header-bold">
                          Related Articles
                        </div>
                        <div className="most_popular_ul">
                          <ul
                            className="section__ul"
                            style={{ width: "100%", float: "left" }}
                          >
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/ms-teams-bot-workplace-support-bot">
                                MS Teams Bot - Your ultimate workplace support
                              </a>
                            </li>
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/conversational-ai-chatbot">
                                {" "}
                                What Is Conversational AI? A Guide to
                                Conversational AI Chatbots
                              </a>
                            </li>
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/build-or-buy-virtual-agent">
                                Build or buy Virtual Agent
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="button_last">
                        <button>
                          <a href="https://blog.workativ.com/category/conversational-ai">
                            Conversational AI
                          </a>
                        </button>
                      </div>
                    </div>
                    <div className="sticky_right_img">
                      <a href="/conversational-ai-platform/case-studies/goto-implements-chatbot-for-it-helpdesk-automation">
                        {" "}
                        <img src={topImage} alt="goto testimonial cta" />{" "}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* {isSmall ? null : <OnScrollPopup />} */}

            {/* <GoToPopup />
            <CookiesPoup /> */}
            <RequestForm isFooterForm={true} />
          </Layout>
        </Container>
      </section>
    </>
  );
}

const NocodeWrapper = () => {
  return (
    <div className="nocode_wrapper mt-0 ">
      <h4>No Code - Free Conversational AI Chatbot </h4>
      <button>
        <a
          href="https://assistant.workativ.com/authentication/u/direct-signup"
          alt="Conversational AI chatbot"
        >
          Get Started
        </a>
      </button>
    </div>
  );
};
